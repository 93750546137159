/*
Per la logica usata nei selectors si veda
https://redux.js.org/usage/deriving-data-selectors
e
https://react-redux.js.org/api/hooks
*/

import { 
  createAsyncThunk, 
  createSlice, 
  PayloadAction, 
  createSelector } 
from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import {
  getCampaigns, getOrder
} from './campaignsAPI';
import { IDeviceEvent, IOrder } from "../campaigns/PaparazzoTypes";// A mock function to mimic making an async request for data
import { ReactNode } from 'react';
import { screenshotBasePath } from '../../commons/config/settings';
import orderSortFn from './ordersSortFn';
export interface CampaignState {
  fastSearch: string;
  mongoTxtQuery: string;
  list: Array<IOrder>;
  activeOrderId: number;
  ordersSortCriteria: string;
  ordersSortDirection: 'ASC' | 'DESC',
  orders: Array<IOrder>;
  status: 'idle' | 'loading' | 'failed';
  modalComponentActive: ReactNode | undefined,
  isModalOpen: boolean,
  isSelectionEnabled: boolean,
  selectedForSending: Array<string>,
  lastShootedOrders: Array<string>,
  eventFilter: {
    clientName?: string;
    placements?: Array<string>,
    testate?: Array<string>,
    lineItems?: Array<string>,
    device?: string,
    status?: string,
    selectedDates?: string
  },
}

export interface IOrderQuery {
  limit: number,
  status?: string,
  device?: string,
  txtQuery?: string,
  forceFetch?: boolean,
  from?: number,
  to?: number
}

const fakeOrder = {
  status: "fake", 
  order: "", 
  id: 0, 
  lineItems: [], 
  deviceEvents: [], 
  lineItemsCount: 0, 
  deviceEventsCount: {desktop: 0, mobile: 0, tablet: 0, total: 0},
  startDate: 0, endDate: 0,
  mnzClientName: ""
}

const initialState: CampaignState = {
  fastSearch: '',
  mongoTxtQuery: '',
  list: [],
  activeOrderId: 0,
  orders: [],
  lastShootedOrders: [],
  ordersSortCriteria: 'EVENTS_COUNT_TOTAL',
  ordersSortDirection: 'DESC',
  status: 'idle',
  modalComponentActive: null,
  isModalOpen: false,
  isSelectionEnabled: false,
  selectedForSending: [],
  eventFilter: {},
};

export const fetchCampaigns = createAsyncThunk('campaigns/fetchCampaigns', async (query: IOrderQuery) => {
  const response = await getCampaigns(query);
  return response;
});

export const fetchOrder = createAsyncThunk('campaigns/fetchOrder', async (orderId: number) => {
  const response = await getOrder(orderId);
  return response;
});

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setFastSearch: (state, action: PayloadAction<string>) => {
      state.fastSearch = action.payload;
    },
    setMongoTxtQuery: (state, action: PayloadAction<string>) => {
      state.mongoTxtQuery = action.payload;
    },
    setModalOpen: (state, action: PayloadAction<ReactNode>) => {
      state.isModalOpen = !state.isModalOpen;
      state.modalComponentActive = action.payload;
    },
    setActiveOrderId: (state, action: PayloadAction<number>) => {
      state.activeOrderId = action.payload;
    },
    setSelectionEnabled: (state) => {
      state.isSelectionEnabled = !state.isSelectionEnabled;
      state.selectedForSending = [];
    },
    resetSelectionEnabled: (state) => {
      state.isSelectionEnabled = false;
      state.selectedForSending = [];
    },
    toggleSelectedForSend: (state, action: PayloadAction<string>) => {
      const exIndex = state.selectedForSending.findIndex((key) => key === action.payload);

      if (exIndex > -1) {
        state.selectedForSending.splice(exIndex, 1);
      } else {
        state.selectedForSending.push(action.payload);
      }
    },
    resetSelectedForSend: (state) => {
      state.selectedForSending = [];
    },
    selectAllForSend: (state, action: PayloadAction<number>) => {
      //@ts-ignore
      /*
      ESTRAE TUTTI I DEVICE EVENTS ID IN UN ARRAY
      state.selectedForSending = state.orders.reduce((ids, orderNext) => (
        [
          ...ids,
          ...orderNext.deviceEvents.map(({id}) => id)
        ]
      ), []);
      */

      const findDeviceEvents = state.orders
        .filter(({ id }) => id === action.payload)[0].deviceEvents

      findDeviceEvents.forEach(deviceEvent => {
        const { date , sourceURL, placement } = deviceEvent;
        const key = btoa(`${sourceURL}${placement}${date}`);;
        deviceEvent.urlClippedBase && state.selectedForSending.push(key);
      })

    },
    setOrdersSort: (state, action: PayloadAction<string>) => {
      state.ordersSortCriteria = action.payload;
      state.ordersSortDirection === "DESC"
        ? state.ordersSortDirection = "ASC"
        : state.ordersSortDirection = "DESC";
    },
    setEventFilter: (state, action: PayloadAction<any>) => {
      state.eventFilter = action.payload;
      console.log(state.eventFilter)
    },
    resetEventFilter: (state) => {
      state.eventFilter = {};
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaigns.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCampaigns.fulfilled, (state, action) => {
        state.status = 'idle';
        state.orders = action.payload.orders;
        state.lastShootedOrders = action.payload.ordersLastShooted;
        //.filter((a:IPaparazzoEvent) => a.deviceEvents.length > 0)
        //.sort((a:IPaparazzoEvent, b:IPaparazzoEvent) => b.deviceEvents.length - a.deviceEvents.length);
      })
      .addCase(fetchCampaigns.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrder.fulfilled, (state, action) => {
        state.status = 'idle';
        const idx = state.orders.length > 0 ? state.orders.findIndex(order => order.id === action.payload.id) : 0;
        state.orders[idx] = action.payload;
        state.activeOrderId = action.payload.id;
        
      })
      .addCase(fetchOrder.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  setFastSearch,
  setMongoTxtQuery,
  setModalOpen,
  setActiveOrderId,
  setSelectionEnabled,
  resetSelectionEnabled,
  toggleSelectedForSend,
  resetSelectedForSend,
  selectAllForSend,
  setOrdersSort,
  setEventFilter,
  resetEventFilter,
} = campaignsSlice.actions;



const selectCampaignState = (state: RootState) => state.campaigns;
const selectOrderSortCriteria = createSelector([selectCampaignState], campaignState => campaignState.ordersSortCriteria);
const selectOrderSortDirection = createSelector([selectCampaignState], campaignState => campaignState.ordersSortDirection);

export const selectOrders = createSelector(
  [selectCampaignState, selectOrderSortCriteria, selectOrderSortDirection], 
  (campaignState, ordersSortCriteria, ordersSortDirection) => {
    try {
      return [...campaignState.orders].filter((order) => order.deviceEventsCount.total && order.deviceEventsCount.total > 7).sort(
        orderSortFn(ordersSortCriteria, ordersSortDirection)
      );
    } catch(err) {
      console.log('error sorting orders');
      return campaignState.orders;
    }
    
  }
);


export const selectSelectedForSending = createSelector([selectCampaignState], campaignState => campaignState.selectedForSending);
export const selectSelectedForSendingLength = createSelector(selectSelectedForSending, (selectedForSending) => selectedForSending.length);


const selectCardKey = (state: RootState, cardKey: string) => cardKey;

export const selectIsCardSelected = createSelector(
  [selectSelectedForSending, selectCardKey],
  (selectedItems, cardKey) => selectedItems.includes(cardKey)
);
export const selectLoadStatus = createSelector([selectCampaignState], campaignState => campaignState.status);
export const selectIsSelEnabled = createSelector([selectCampaignState], campaignState => campaignState.isSelectionEnabled);
export const selectIsModalOpen = createSelector([selectCampaignState], campaignState => campaignState.isModalOpen);
export const selectModalComponentActive = createSelector([selectCampaignState], campaignState => campaignState.modalComponentActive);
export const selectTxtFastSearch = createSelector([selectCampaignState], campaignState => campaignState.fastSearch);
export const selectTxtMongo = createSelector([selectCampaignState], campaignState => campaignState.mongoTxtQuery);

const selectOrderId = (state: RootState, orderId = "0") => orderId;
export const selectOrderDetailsById = createSelector(
  [selectOrders, selectOrderId],
  (selectedOrders, orderId) => selectedOrders.filter(selOrder => selOrder.id.toString() === orderId)[0]
  );

export const selectActiveOrder = createSelector(
  [selectCampaignState], (campaignState) => {
    const filtered =  campaignState.orders.filter(
      order => order.id === campaignState.activeOrderId
    );

    return filtered.length > 0 ? filtered[0] : fakeOrder;
  }
);


const selectOrderStatus = (state: RootState, orderStatus = "delivering") => orderStatus;
export const selectOrdersByStatus = createSelector(
  [selectOrders, selectOrderStatus],
  (selectedOrders, orderStatus) => selectedOrders.filter((order) => order.deviceEventsCount.total && order.deviceEventsCount.total > 7).filter(selOrder => selOrder.status === orderStatus)
);


export const selectOrdersByFastSearch = createSelector(
  [selectOrders, selectTxtFastSearch],
  (selectedOrders, txtFastSearch) => selectedOrders.filter((order) => order.deviceEventsCount.total && order.deviceEventsCount.total > 7).filter(selOrder => (
    selOrder.order.match(new RegExp(`.*${txtFastSearch}.*`, 'i')) ||
    selOrder.mnzClientName?.match(new RegExp(`.*${txtFastSearch}.*`, 'i')) ||
    selOrder.id.toString().match(new RegExp(`.*${txtFastSearch}.*`, 'i'))
  ))
);

export const selectLastShootedOrdersNames = createSelector([selectCampaignState], campaignState => campaignState.lastShootedOrders);
export const selectLastShootedOrders = createSelector(
  [selectOrders, selectLastShootedOrdersNames], 
  (orders, lastShootedOrdersNames) => 
    orders
      .filter((order) => lastShootedOrdersNames.includes(order.order))
      .sort((orderA, orderB) => (
        parseInt(orderB.lastSavedEventTime) - parseInt(orderA.lastSavedEventTime))
      ).slice(0, 100)
  );


export const selectFilteredDeviceEvents = (orderId: string) => (state: RootState): Array<IDeviceEvent> => {
  const { campaigns } = state;
  const { eventFilter, orders } = campaigns;
  const orderDetail = orders.filter((order: IOrder) => order.id.toString() === orderId)[0];

  if (!orderDetail || !Array.isArray(orderDetail.deviceEvents)) {
    return [];
  }
  
  return orderDetail.deviceEvents?.filter((deviceEvent: any) => {
    const { device, placements, testate, selectedDates, lineItems } = eventFilter;
    const timeMargin = 60 * 60 * 24 * 1000;

    const hasLineItemsFilter = Array.isArray(lineItems) && lineItems.length > 0;
    const hasDeviceFilter = device && device.length > 0;
    const hasPlacementsFilter = Array.isArray(placements) && placements.length > 0;
    const hasTestateFilter = Array.isArray(testate) && testate.length > 0;
    const hasDates = Array.isArray(selectedDates) && selectedDates.length > 0 &&
      typeof selectedDates[0] !== "undefined" &&
      typeof selectedDates[1] !== "undefined";

    if (hasDeviceFilter || hasPlacementsFilter || hasTestateFilter || hasDates || hasLineItemsFilter) {
      const matchDevice = !hasDeviceFilter || device === deviceEvent.device;
      const matchTestata = !hasTestateFilter || testate.includes(deviceEvent.testata);
      const matchLineItems = !hasLineItemsFilter || lineItems?.includes(deviceEvent.lineItem);
      const matchPlacement = !hasPlacementsFilter || placements.includes(deviceEvent.placement);
      const matchDates = (
        !hasDates || (
          selectedDates[0] < new Date(deviceEvent.date).getTime() &&
          (selectedDates[1] + timeMargin) > new Date(deviceEvent.date).getTime()
        )
      );

      return matchDevice && matchTestata && matchPlacement && matchDates && matchLineItems;
    }

    return true;
  }).reverse();
}


export default campaignsSlice.reducer;
