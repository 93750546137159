import WIPImage from '../../commons/images/svgs/wip_image.svg';

const WorkInProgress = () => {

    return (
        <div className='wip'>

            <h2>Feature in lavorazione</h2>

            <img
                className='wip__image'
                src={WIPImage} alt="pagina in costruzione"
            />

        </div>

    );
}

export default WorkInProgress;