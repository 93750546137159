import AWS from 'aws-sdk';

export const mongoEndpoint = 'https://eu-central-1.aws.data.mongodb-api.com/app/paparazzoreporter-kroly/endpoint';
export const mongoHeaders = {
  'Content-Type': 'application/json'
};
export const mongoDB = 'Paparazzo';
export const mongoEventsCollection = 'Orders13_demo';
export const mongoSettingsCollection = 'Settings_demo';
export const mongoLineItemsCollection = 'LineItems';
export const screenshotBasePath = 'https://fra1.digitaloceanspaces.com/';
export const storageConfig = {
  s3: {
    endpoint: 'fra1.digitaloceanspaces.com',
    useAccelerateEndpoint: false,
    credentials: new AWS.Credentials (
      'DO00FVLJ78FHMDVXAHXJ',
      'WyBRlmH9gKbcam9K/sHeqUvm4ql/9i5IfSFDhwFu7uI'
    ),
  },
};

export const cacheOrdersLastFetchKey = "pzo__orders_last_fetch_time";
export const cacheOrdersKey = "pzo__orders";
export const cacheOrdersLastShootedKey = "pzo__orders_last_shooted";
export const cacheOrdersTTLmsec = 1 * 60e3;
//export const cacheOrdersTTLmsec = 20e3;
