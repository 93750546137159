import { useEffect } from "react";

export const editOrderName = (lineItem, order) => {
    let subString = lineItem.lineItem.replace(order.order.substring(0, order.order.length - 5), '')
        .split("_");
    // let splitString = subString.split("_");
    // let updatedString = splitString[1].slice(0, -7);
    // splitString[1] = updatedString;
    // let recombinedString = splitString.join("_");
    // return recombinedString

    return subString
}

export const useDisableBodyScroll = (state) => {
    useEffect(() => {
        if (state) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'unset';
        }
    }, [state]);
};


export const validateNames = (names) => {
    var regex = /^[a-zA-Z\s]{2,}$/;
    if (regex.test(names)) {
        return '';
    } else if (names === '') {
        return 'Il campo "Nome" è richiesto';
    } else {
        return 'Il nome non è valido';
    }
}

export const validateEmails = (emails) => {
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(;|,|$|\s)+/;
    if (regex.test(emails)) {
        return '';
    } else if (emails === '') {
        return 'Il campo "To" è richiesto';
    } else {
        return 'Una o più email non sono valide';
    }
}

export const validateCcEmails = (ccEmails) => {
    var regex = /^$|^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}(\s*,\s*[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,})*$/;
    if (regex.test(ccEmails)) {
        return '';
    } else {
        return 'Una o più email non sono valide';
    }
}

export const validateSubject = (subject) => {
    var regex = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]{1,200}$/;
    if (regex.test(subject)) {
        return '';
    } else if (subject === '') {
        return 'Il campo "oggetto" è richiesto'
    } else {
        return 'Il campo oggetto non è valido';
    }
}

