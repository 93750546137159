import axios from 'axios';
import {
  ILineItem
} from './PaparazzoTypes.js'

import {
  mongoEndpoint,
  cacheOrdersTTLmsec,
  cacheOrdersLastFetchKey,
  cacheOrdersKey,
  cacheOrdersLastShootedKey,
  mongoEventsCollection,
  mongoSettingsCollection
} from '../../commons/config/settings';

import {IOrderQuery} from './campaignsSlice';

function getLineItemNameFromId(lineItems: Array<ILineItem>, lineitemId?:number) {
  if (!lineitemId) {
    return "";
  }

  return lineItems.filter(({id}: {id:number}) => lineitemId === id)[0].lineItem;
}


const buildQueryString = (query: IOrderQuery) => {
  const _q = "collection=" + mongoEventsCollection;
  const _limit = query.limit ? `&limit=${query.limit}` : '';
  const _status = query.status ? `&status=${query.status}` : '';
  const _device = query.device ? `&device=${query.device}` : '';
  const _from = query.from ? `&from=${query.from}` : '';
  const _to = query.to ? `&to=${query.to}` : '';
  const _mnzClientName = query.txtQuery ? `&txtQuery=${query.txtQuery}` : '';

  return `${_q}${_limit}${_status}${_device}${_from}${_to}${_mnzClientName}`;
}

const fetchOrdersWithMongo = async(query: IOrderQuery) => {
  const qString = buildQueryString(query);
  const dataOrders = await axios.get(`${mongoEndpoint}/campaigns_v2?${qString}`);
  const dataLastShooted = await axios.get(`${mongoEndpoint}/getLastShootedOrders?collection=${mongoSettingsCollection}`);
  const orders = dataOrders.data;
  const ordersLastShooted = dataLastShooted.data.value;

  localStorage.setItem(cacheOrdersKey, JSON.stringify(orders));
  localStorage.setItem(cacheOrdersLastShootedKey, JSON.stringify(ordersLastShooted));
  localStorage.setItem(cacheOrdersLastFetchKey, new Date().getTime().toString());

  return [orders, ordersLastShooted];
}

function mustUseCache(forceFetch = false) {
  const cacheLastTime = localStorage.getItem(cacheOrdersLastFetchKey) || "1";
  const now = new Date().getTime();
  const cachedOrders = JSON.parse(localStorage.getItem(cacheOrdersKey) || "[]");
  const cachedOrdersLastShooted = JSON.parse(localStorage.getItem(cacheOrdersLastShootedKey) || "[]");
  const elapsedTime = now - parseInt(cacheLastTime);

  console.log("----", cacheOrdersTTLmsec);
  
  return (
    forceFetch === false &&
    cachedOrders.length > 0 && 
    elapsedTime < cacheOrdersTTLmsec &&
    (Array.isArray(cachedOrdersLastShooted) && cachedOrdersLastShooted.length > 0)
  );
}

export async function getCampaigns(query: IOrderQuery) {
  //da json statico
  //const data = await axios.get('https://fra1.digitaloceanspaces.com/kf-service/paparazzo/data/display2.json');

  let orders, ordersLastShooted;
  const forceFetch = typeof query.forceFetch === 'undefined' ? false : query.forceFetch;
  
  //const _mustUseCache = mustUseCache(forceFetch);

  const _mustUseCache = false;

  //console.log("_mustUseCache", _mustUseCache);

  if (false === _mustUseCache) {
    [orders, ordersLastShooted] = await fetchOrdersWithMongo(query);
  } else {
    orders = JSON.parse(localStorage.getItem(cacheOrdersKey) || "");
    ordersLastShooted = Array.from(
      new Set(
        JSON.parse(localStorage.getItem(cacheOrdersLastShootedKey) || "[]")
      )
    );
  }

  /*
  orders = orders.map((order: IOrder) => {
    return {
      ...order,
      order: order.order.replaceAll(' ', '').replaceAll('”', '').replaceAll('\'', '').replaceAll('+', '_').replaceAll('\' ', '_').replaceAll(')', '_')
    }
  });
  */
 
  return {
    orders,
    ordersLastShooted
  };
}

export async function getOrder(orderId: number) {
  const result = await axios.get(`${mongoEndpoint}/order?collection=${mongoEventsCollection}&id=${orderId}`);

  return result.data;
}

export async function getUsers() {
  const data = await axios.get('https://fra1.digitaloceanspaces.com/kf-service/paparazzo/data/paparazzo_test_users.json');

  console.log(data);

  return data;
}